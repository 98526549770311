import { IResponsivePaddingObj } from 'utils/types';

enum curatorCarouselBreakpoints {
  OneImage = 0,
  TwoImages = 600,
  ThreeImages = 900,
  FourImages = 1200,
  FiveImages = 1500,
  SixImages = 1800,
}

export const carosuelContainerResponsivePaddingConfig: IResponsivePaddingObj[] =
  [
    {
      breakpoint: curatorCarouselBreakpoints.OneImage,
      paddingBottomPercentage: '105%',
    },
    {
      breakpoint: curatorCarouselBreakpoints.TwoImages,
      paddingBottomPercentage: '54%',
    },
    {
      breakpoint: curatorCarouselBreakpoints.ThreeImages,
      paddingBottomPercentage: '36%',
    },
    {
      breakpoint: curatorCarouselBreakpoints.FourImages,
      paddingBottomPercentage: '26.7%',
    },
    {
      breakpoint: curatorCarouselBreakpoints.FiveImages,
      paddingBottomPercentage: '21.5%',
    },
    {
      breakpoint: curatorCarouselBreakpoints.SixImages,
      paddingBottomPercentage: '17.9%',
    },
  ];
